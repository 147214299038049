import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { barClasses } from '..';
import { FireflyBaseChartComponent } from '../common/base-components/base-chart-component';
import type { ChartDataEntry, ChartDataSeries, ChartLegendOptions } from '../common/models/common-chart-models';

@Component({
  selector: 'f-grouped-bar-chart',
  templateUrl: './grouped-bar-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyGroupedBarChartComponent extends FireflyBaseChartComponent implements OnChanges {
  @Input() barClasses?: string[];
  @Input() data!: ChartDataSeries[];
  @Input() groupPadding = 0.25;

  @Output() barClick: EventEmitter<{ data: ChartDataSeries } | undefined | null> = new EventEmitter();

  chartComponent = FireflyGroupedBarChartComponent;
  chartLegendOptions!: ChartLegendOptions;

  protected legendRootClass = 'f-grouped-bar-chart-legend';
  protected padding = 0.25;

  get chartBarClasses(): string[] {
    return this.barClasses || barClasses;
  }

  get chartLegendRootClass(): string {
    return this.barClasses ? '' : this.legendRootClass;
  }

  override ngOnChanges(_changes?: SimpleChanges): void {
    super.ngOnChanges();
    if (_changes && (_changes['barClasses'] || _changes['legendOptions'])) {
      this.chartLegendOptions = {
        ...this.legendOptions,
        data: [
          ...this.legendOptions.data.map((item, i) => ({
            ...item,
            markerCssClass: item.markerCssClass || this.chartBarClasses[i]
          }))
        ]
      };
    }
  }

  onGroupClick($event: { data: ChartDataSeries } | null) {
    this.barClick.emit($event);
  }

  protected getYDomain(data: ChartDataSeries[] | ChartDataEntry[], startFromZero = true, useSuper = false): number[] {
    if (useSuper) return super.getYDomain(data, startFromZero);

    const values = (data as ChartDataSeries[])
      .reduce(
        (acc, curr) => [
          ...acc,
          ...(curr.series as ChartDataEntry[])
            .map(d => d.value as number | undefined)
            .filter(v => typeof v === 'number')
        ],
        [] as Array<number | undefined>
      )
      .filter(v => typeof v === 'number') as number[];

    const adjustedValues = startFromZero ? [0, ...values] : values;
    const min = Math.min(...adjustedValues);
    const max = Math.max(...adjustedValues);

    return startFromZero ? [min, max] : this.getDomainWithPaddings([min, max]);
  }

  protected shouldAdjustYScaleDomain(max: number) {
    return false;
  }
}
