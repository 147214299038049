import { DateTime } from 'luxon';
import { Breakpoint } from '@capital-access/firefly/components';
import { ContactSuggesterItem } from './models/contact-suggester-item.model';
import { SelectedDateRange } from './models/selected-date-range.model';

export const EMAILS_API_BASE_URL = '/api/email/';
export const TAKE = 50;
export const MAX_STORAGE_LIMIT_GB = 15;

export const DEFAULT_SELECTED_RANGE = {
  start: DateTime.now().minus({ months: 12 }).startOf('day').toJSDate(),
  end: DateTime.now().endOf('day').toJSDate(),
  localizationKey: 'dateWithToday'
} as SelectedDateRange;

export const isTablet: () => boolean = (): boolean => {
  return window.innerWidth < Breakpoint.Lg;
};

export const EMAILS_DEFAULT_FROM = 'no-reply@spglobal.com';
export const EMAILS_DEFAULT_NAME = 'System User';
export const DEFAULT_CONTACT: ContactSuggesterItem = {
  id: -1,
  name: EMAILS_DEFAULT_NAME,
  compositeKey: '',
  email1: EMAILS_DEFAULT_FROM,
  displaySelectedName: EMAILS_DEFAULT_FROM,
  isPrimaryEmailSelected: true,
  isDefault: true
};
